@tailwind base;
@tailwind components;
@tailwind utilities;

/* Geologica-VariableFont_CRSVSHRPslntwght.ttf */
@font-face {
  font-family: 'Geologica-VariableFont_CRSVSHRPslntwght';
  src: url('../public/images/Geologica-VariableFont_CRSVSHRPslntwght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Averta-Cyrillic-Bold.otf */
@font-face {
  font-family: 'Averta-Cyrillic-Bold';
  src: url('../public/images/Averta-Cyrillic-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

body {
  margin: 0;
  /* height: 300vh; */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Nunito',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.box {
  width: 150px;
  height: 150px;
  border-radius: 1em;
  background-color: #f9f07e;
  margin-left: auto;
  margin-right: auto;
}

.magic {
  width: 200px;
  height: 200px;
  background-color: #f1f1f1;
  border-radius: 20px;
  margin-left: auto;
  margin-right: auto;
}