.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.overflow-hidden {
  overflow: hidden;
}

.btn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-style: none;
  background: turquoise;
  cursor: pointer;
  z-index: 1;
}

.navbar {
  height: 100vh;
  width: 100%;
  margin: none;
  /* background: linear-gradient(51deg,#272f3d,#191f29,#182130); */
  background-size: 180% 180%;
  position: fixed;
}

.navList {
  padding: 100px 70px 200px 70px;
}

.nav-item {
  font-size: 20px;
  list-style: none;
  color: white;
  cursor: pointer;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.gradient-background {
  background: linear-gradient(300deg, #333333, #4c4c4c, #1c3f44, #182021);
  background-size: 240% 240%;
  animation: gradient-animation 24s ease infinite;
}

.gradientMasterSet {
  background: linear-gradient(51deg, #272f3d, #191f29, #182130);
  background-size: 180% 180%;
  animation: gradient-animation 18s ease infinite;
}

.gradientSecondarySet {
  background: linear-gradient(51deg, #272f3d, #212b3c, #343f53);
  background-size: 180% 180%;
  animation: gradient-animation 18s ease infinite;
}

.bgDarkGradient4 {
  background: linear-gradient(300deg, #323232, #212121, #171717, #000000);
  background-size: 240% 240%;
  animation: gradient-animation 24s ease infinite;
}

.bgDarkGradient {
  background: linear-gradient(248deg, #252525, #040404, #1d1d1d);
  background-size: 180% 180%;
  animation: gradient-animation 18s ease infinite;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.glow {
  animation: move 5s linear infinite;
  offset-path: rect(0% auto 100% auto);
  background: radial-gradient(#fff, #f1f5f9, transparent);
}

@keyframes move {
  0% {
    offset-distance: 0%;
  }

  100% {
    offset-distance: 100%;
  }
}

@font-face {
  font-family: "Geist Sans";
  src: url("https://assets.codepen.io/605876/GeistVF.ttf") format("truetype");
}

.root {
  background: #fff;
  --bg: hsl(0 0% 2%);
  --color: hsl(0 0% 100% / 0.1);
  --underline-width: 1lh;
  --underline-block-width: 200vmax;
  --underline-color: hsl(0 0% 50% / 0.15);
  --underline-color-hover: hsl(180 100% 50% / 1);
  --underline-transition: 5s;
  --finish-fill: hsl(0deg 0% 10.2%);
  --accent: hsl(0deg 0% 24.34%);
  --fill: hsl(0deg 0% 19.47%);
}

.root:has(#driver:checked) main {
  outline: 1rem dashed hsl(0 80% 50% / 0.5);
  background: hsl(0 80% 50% / 0.1);
}

.root p {
  overflow: hidden;
  margin: 0;
}

.root p > span {
  outline-color: hsl(10 80% 50%);
  outline-offset: 1ch;
  font-size: clamp(5rem, 5vw + 1rem, 10rem);
  color: var(--color);
  text-decoration: none;
  background-image:
		/* First one is the highlight */ linear-gradient(90deg, transparent calc(100% - 8ch), var(--accent) calc(100% - 8ch)),
    linear-gradient(90deg, var(--fill), var(--fill)), linear-gradient(90deg, var(--underline-color), var(--underline-color));
  background-size: var(--underline-block-width) var(--underline-width), var(--underline-block-width) var(--underline-width),
    100% var(--underline-width);
  background-repeat: no-repeat;
  background-position-x: 0;
  background-position-y: 100%;
  background-clip: text;
  color: var(--finish-fill);
  text-transform: uppercase;
  font-weight: bold;
}

@media (max-width: 768px) {
  .root p > span {
    font-size: 8vmin;
  }
}

.root main {
  height: 200vh;
}

.root section {
  position: sticky;
  top: 0;
  height: 100vh;
  /* width: 100vw; */
  display: grid;
  place-items: center;
}

@supports (animation-timeline: scroll()) {
  @media (prefers-reduced-motion: no-preference) {
    main {
      view-timeline-name: --section;
    }

    .root p > span {
      background-position-x: calc(var(--underline-block-width) * -1), calc(var(--underline-block-width) * -1), 0;
      color: transparent;
      animation-name: fill-up, color-in;
      animation-fill-mode: both;
      animation-timing-function: linear;
      animation-timeline: --section;
      animation-range: entry 100% cover 50%, cover 50% exit 0%;
    }

    @keyframes fill-up {
      to {
        background-position-x: 0, 0, 0;
      }
    }

    @keyframes color-in {
      to {
        color: var(--finish-fill);
      }
    }
  }
}

.resizable-image { 
  max-width: 100%; 
  max-height: 100%; 
  display: block; 
  margin: auto; 
} 

section {
  position: relative;
  width: 100%;
  height: 100%;
}
section::after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 80%;
  /* background: -webkit-linear-gradient(top,rgba(0,0,0,0) 0,rgba(0,0,0,.8) 80%,rgba(0,0,0,.8) 100%); */
  /* background: linear-gradient(to bottom,rgba(0,0,0,0) 0,rgba(0,0,0,.8) 80%,rgba(0,0,0,.8) 100%); */
}

.demo div {
  position: absolute;
  bottom: 20px;
  left: 50%;
  z-index: 2;
  display: inline-block;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  color: #fff;
  font : normal 400 20px/1 'Josefin Sans', sans-serif;
  letter-spacing: .1em;
  text-decoration: none;
  transition: opacity .3s;
}
.demo a:hover {
  opacity: .5;
}
#section10 div {
  padding-top: 60px;
}
#section10 div span {
  position: absolute;
  top: 0;
  left: 50%;
  width: 30px;
  height: 50px;
  margin-left: -15px;
  border: 2px solid #fff;
  border-radius: 50px;
  box-sizing: border-box;
}
#section10 div span::before {
  position: absolute;
  top: 10px;
  left: 50%;
  content: '';
  width: 6px;
  height: 6px;
  margin-left: -3px;
  background-color: #fff;
  border-radius: 100%;
  -webkit-animation: sdb10 2s infinite;
  animation: sdb10 2s infinite;
  box-sizing: border-box;
}

.progressWrapper {
  width: 90px;
}
.progressBar {
  background-color: #1494a3; 
  height: 10px;
}

@-webkit-keyframes sdb10 {
  0% {
    -webkit-transform: translate(0, 0);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    -webkit-transform: translate(0, 20px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes sdb10 {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    transform: translate(0, 20px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.letsgo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 100%;
}

.effect1 {
  color: #fff;
  text-decoration: none;
  font-family: sans-serif;
  font-size: 24px;
  position: relative;
  padding: 10px 50px 10px 20px;

  -webkit-transition: all 0.3s;

  -o-transition: all 0.3s;

  transition: all 0.3s;

  -webkit-transform: scale(3);

      -ms-transform: scale(3);

          transform: scale(3); /*change scale(3) to scale(1)*/
}

.effect1 .bg {
  background: #222;
  width: 30px;
  height: 2px;
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -1px;
  z-index: -1;

  -webkit-transition: all 0.3s;

  -o-transition: all 0.3s;

  transition: all 0.3s;
}

.effect1:hover {
  padding-right: 20px;
  color: #fff;
}

.effect1:hover .bg {
  height: 100%;
  width: 100%;
  -webkit-transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);
          transform: translate(0, -50%);
}

.effect1 .bg:before, .effect1 .bg:after {
  content: '';
  height: 2px;
  width: 10px;
  background: #222;
  position: absolute;
  right: -2px;      
  -webkit-transition: all 0.3s;      
  -o-transition: all 0.3s;      
  transition: all 0.3s;
}

.effect1 .bg:before {
  bottom: 3px;
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
}
.effect1 .bg:after{
  top: 3px;
  -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.effect1:hover .bg:before,
.effect1:hover .bg:after{
  right: 0;
}

.effect1:hover .bg:before{
  bottom: 6px;
}

.effect1:hover .bg:after{
  top: 6px;
}

/* animatedBorderButton */

.type--A{
  --line_color : #dee2e6 ;
  --back_color : #FFECF6  ;
}
.type--B{
  --line_color : #1b1919 ;
  --back_color : #E9ECFF
}
.type--C{
  --line_color : #00135C ;
  --back_color : #DEFFFA 
}
.animatedBorderButton {
    position : relative ;
    z-index : 0 ;
    width : 240px ;
    height : 56px ;
    text-decoration : none ;
    font-size : 14px ; 
    font-weight : bold ;
    color : var(--line_color) ;
    letter-spacing : 2px ;
    transition : all .3s ease ;
}
.button__text{
    display : flex ;
    justify-content : center ;
    align-items : center ;
    width : 100% ;
    height : 100% ;
}
.animatedBorderButton::before,
.animatedBorderButton::after,
.button__text::before,
.button__text::after{
    content : '' ;
    position : absolute ;
    height : 3px ;
    border-radius : 2px ;
    background : var(--line_color) ;
    transition : all .5s ease ;
}
.animatedBorderButton::before{
    top : 0 ;
    left : 54px ;
    width : calc( 100% - 56px * 2 - 16px ) ;
}
.animatedBorderButton::after{
    top : 0 ;
    right : 54px ;
    width : 8px ;
}
.button__text::before{
    bottom : 0 ;
    right : 54px ;
    width : calc( 100% - 56px * 2 - 16px ) ;
}
.button__text::after{
    bottom : 0 ;
    left : 54px ;
    width : 8px ;
}
.button__line{
    position : absolute ;
    top : 0 ;
    width : 56px ;
    height : 100% ;
    overflow : hidden ;
}
.button__line::before{
    content : '' ;
    position : absolute ;
    top : 0 ;
    width : 150% ;
    height : 100% ;
    box-sizing : border-box ;
    border-radius : 300px ;
    border : solid 3px var(--line_color) ;
}
.button__line:nth-child(1),
.button__line:nth-child(1)::before{
    left : 0 ;
}
.button__line:nth-child(2),
.button__line:nth-child(2)::before{
    right : 0 ;
}
.animatedBorderButton:hover{
    letter-spacing : 3px ;
}
.animatedBorderButton:hover::before,
.animatedBorderButton:hover .button__text::before{
    width : 8px ;
}
.animatedBorderButton:hover::after,
.animatedBorderButton:hover .button__text::after{
    width : calc( 100% - 56px * 2 - 16px ) ;
}
.button__drow1,
.button__drow2{
    position : absolute ;
    z-index : -1 ;
    border-radius : 16px ;
    transform-origin : 16px 16px ;
}
.button__drow1{
    top : -16px ;
    left : 40px ;
    width : 32px ;
    height : 0;
    transform : rotate( 30deg ) ;
}
.button__drow2{
    top : 44px ;
    left : 77px ;
    width : 32px ;
    height : 0 ;
    transform : rotate(-127deg ) ;
}
.button__drow1::before,
.button__drow1::after,
.button__drow2::before,
.button__drow2::after{
    content : '' ;
    position : absolute ;
}
.button__drow1::before{
    bottom : 0 ;
    left : 0 ;
    width : 0 ;
    height : 32px ;
    border-radius : 16px ;
    transform-origin : 16px 16px ;
    transform : rotate( -60deg ) ;
}
.button__drow1::after{
    top : -10px ;
    left : 45px ;
    width : 0 ;
    height : 32px ;
    border-radius : 16px ;
    transform-origin : 16px 16px ;
    transform : rotate( 69deg ) ;
}
.button__drow2::before{
    bottom : 0 ;
    left : 0 ;
    width : 0 ;
    height : 32px ;
    border-radius : 16px ;
    transform-origin : 16px 16px ;
    transform : rotate( -146deg ) ;
}
.button__drow2::after{
    bottom : 26px ;
    left : -40px ;
    width : 0 ;
    height : 32px ;
    border-radius : 16px ;
    transform-origin : 16px 16px ;
    transform : rotate( -262deg ) ;
}

.animatedBorderButton_container{
    width : 100% ;
    display : flex ;
    flex-direction : column ;
    justify-content : center ;
    align-items : center ;
}

/* = */

.contactUsNow {
  transition: background-color 0.3s ease;
}

.contactUsNow:hover {
  background-color: #dee2e6;
}

.circleCall .contactUsNow {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.circleCall .overflow-hidden {
  border-radius: 50%;
}

.circleCall .contactUsNow h1 {
  line-height: normal;
  margin: 10px 0 0 0;
  font-size: 10px;
  width: 80px;
  font-weight: bold;
}

/* Magenet nav.magnetButtonWrapper Button */
/* Magenet nav.magnetButtonWrapper Button */
/* Magenet nav.magnetButtonWrapper Button */

@layer foundation {
	body::before {
    /* --line: hsl(0 0% 20% / 0.2); */
    content: "";
    height: 100vh;
    width: 100vw;
    position: fixed;
    background:
      linear-gradient(90deg, var(--line) 1px, transparent 1px 10vmin) 0 -5vmin / 10vmin 10vmin,
      linear-gradient(var(--line) 1px, transparent 1px 10vmin) 0 -5vmin / 10vmin 10vmin;
    mask: linear-gradient(-15deg, transparent 60%, white);
    top: 0;
    z-index: -1;
    transform: translate3d(0, 0, -100vmin);
  }

	nav.magnetButtonWrapper ul {
		display: flex;
		flex-wrap: wrap;
		list-style-type: none;
		padding: 0;
		margin: 0;
	}
	nav.magnetButtonWrapper li {
		border-radius: 1.8rem;
		display: grid;
		place-items: center;
	}
	nav.magnetButtonWrapper a {
		text-decoration: none;
		outline-offset: 0.5rem;
		border-radius: 1.8rem;
	}
	nav.magnetButtonWrapper a span {
		display: inline-block;
	}

	.controls {
		position: fixed;
		top: 1rem;
		right: 1rem;
		display: flex;
		gap: 0.5rem;
		color: hsl(0 0% 50%);
		display: grid;
		grid-template-columns: auto 1fr;
	}
}

@layer trickery {
	:root {
		--elastic-out: linear(
	    0, 0.2178 2.1%, 1.1144 8.49%,
	    1.2959 10.7%, 1.3463 11.81%,
	    1.3705 12.94%, 1.3726, 1.3643 14.48%,
	    1.3151 16.2%, 1.0317 21.81%,
	    0.941 24.01%, 0.8912 25.91%,
	    0.8694 27.84%, 0.8698 29.21%,
	    0.8824 30.71%, 1.0122 38.33%, 1.0357,
	    1.046 42.71%, 1.0416 45.7%,
	    0.9961 53.26%, 0.9839 57.54%,
	    0.9853 60.71%, 1.0012 68.14%,
	    1.0056 72.24%, 0.9981 86.66%, 1
	  );
	}
	nav.magnetButtonWrapper {
		--padding-x: calc(var(--item-inline, 1) * 1rem);
		--padding-y: calc(var(--item-block, 0.5) * 1rem);
	}
	/* Implementation for following tab */
	ul:is(:hover, :focus-within) { --follow: 1; }
	:root:has(#follow:checked) ul::after {
		content: "";
		background: hsl(0 0% 95%);
		border-radius: 1.275rem;
		position: fixed;
		opacity: var(--follow, 0);
		top: calc((var(--at) * 1px) + (var(--item-block) * 1rem));
		left: calc((var(--al) * 1px) + (var(--item-inline) * 1rem));
		height: calc((var(--ah) * 1px) - (var(--item-block) * 2rem));
		width: calc((var(--aw) * 1px) - (var(--item-inline) * 2rem));
		z-index: -1;
		pointer-events: none;
		translate:
			calc(clamp(-1, var(--list-x), 1) * var(--padding-x, 1rem))
			calc(clamp(-1, var(--list-y), 1) * var(--padding-y, 1rem));
/*		transition: top 0.2s, left 0.2s, width 0.2s, height 0.2s;*/
		transition:
			top 0.25s,
			left 0.25s,
			width 0.25s,
			height 0.25s,
			opacity 0.25s,
			translate var(--speed, 0.25s) var(--ease, ease-out);
	}
	:root:has(#follow:checked) a {
		background-color: transparent;
	}
	ul:has(li:hover a) {
		--ease: ease;
		--speed: 0.1s;
	}
	ul:has(li a:is(:hover, :focus-within)) {
		--speed: 0s;
	}
	/* End follow implementation	*/
	nav.magnetButtonWrapper li {
		padding: var(--padding-y) var(--padding-x);
		outline: 2px dashed transparent;
		transition: outline-color 0.5s;
	}
	:root:has(#outline:checked) nav.magnetButtonWrapper li {
		outline-color: var(--theme);
	}
	nav.magnetButtonWrapper li:hover {
		--active: 1;
	}
	nav.magnetButtonWrapper li:hover a {
		--ease: ease;
		--speed: 0.1s;
	}
	nav.magnetButtonWrapper li a:is(:hover, :focus-within) {
		--active: 1;
		--speed: 0s;
	}
	nav.magnetButtonWrapper a {
		--accent: hsl(0 0% calc((100 - (var(--active, 0) * 5)) * 1%));
		background-color: var(--accent);
		/* background-color: #fc6601; */
		padding: 1rem;
		outline-color: var(--accent);
	}
	nav.magnetButtonWrapper a span {
		--padding-x: calc(1rem * var(--text-offset, 0.25));
		--padding-y: calc(1rem * var(--text-offset, 0.25));
	}
	:where(nav.magnetButtonWrapper a, nav.magnetButtonWrapper a span) {
		translate:
			calc(clamp(-1, var(--magnet-x), 1) * var(--padding-x, 1rem))
			calc(clamp(-1, var(--magnet-y), 1) * var(--padding-y, 1rem));
		transition: translate var(--speed, 0.25s) var(--ease, ease-out), outline-color 0.25s, background-color 0.25s;
	}
}

/* hover button */
/* hover button */
/* hover button */

.btnHoverEffect {
  letter-spacing: 0.1em;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  line-height: 45px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  width: 100%;
}
.btnHoverEffect:hover {
  text-decoration: none;
}

.effect04 {
  --uismLinkDisplay: var(--smLinkDisplay, inline-flex);
  display: var(--uismLinkDisplay);
  color: #343a40;
  outline: solid  2px #343a40;
  position: relative;
  transition-duration: 0.4s;
  overflow: hidden;
  border-radius : 5px;
}

.effect04::before,
.effect04 span{
    margin: 0 auto;
	transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
	transition-duration: 0.4s;
}

.effect04:hover{
  background-color: #343a40;
}

.effect04:hover span{
  -webkit-transform: translateY(-400%) scale(-0.1,20);
          transform: translateY(-400%) scale(-0.1,20);
}

.effect04::before{
  content: attr(data-sm-link-text);
	color: #FFF;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  -webkit-transform: translateY(500%) scale(-0.1,20);
          transform: translateY(500%) scale(-0.1,20);
}

.effect04:hover::before{
  letter-spacing: 0.05em;
  -webkit-transform: translateY(0) scale(1,1);
          transform: translateY(0) scale(1,1);
}

.containerHoverButton {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding:  40px 0px;
  width: 240px;
  min-height: 45px;
}

/* -- */

.containerServices {
  display: flex;
  width: 100%;
}

.containerServices .sci {
	position: absolute;
	bottom: 50px;
	display: flex;
}

.containerServices .sci div {
	list-style: none;
	margin: 0 10px;
	transform: translateY(40px);
	transition: 0.5s;
	opacity: 0;
}

.containerServices:hover .sci div {
	transform: translateY(0px);
	opacity: 1;
}

.containerServices .containerServicesTitle {
	transition: 0.5s;
  margin-left: 10px;
  margin-right: 10px;
}

.containerServices:hover .containerServicesTitle {
	transform: translateY(-20px);
}

.sub-menu-container {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease;
}

.sub-menu-container.open {
  max-height: 200px; /* Adjust height as needed */
}

.fadedText h1 {
  transform: scale(0.94);
  animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
}
@keyframes scale {
  100% {
    transform: scale(1);
  }
}

.fadedText span {
  display: inline-block;
  opacity: 0;
  filter: blur(4px);
  margin: 0 5px;
  color: rgb(255, 255, 255); 
  mix-blend-mode: difference;
  line-height: 115%;
  font-size: 5vmin;
}

.fadedText span:nth-child(1) {
  animation: fade-in 0.8s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.fadedText span:nth-child(2) {
  animation: fade-in 0.8s 0.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.fadedText span:nth-child(3) {
  animation: fade-in 0.8s 0.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.fadedText span:nth-child(4) {
  animation: fade-in 0.8s 0.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

/* span:nth-child(5) {
  animation: fade-in 0.8s 0.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(6) {
  animation: fade-in 0.8s 0.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(7) {
  animation: fade-in 0.8s 0.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(8) {
  animation: fade-in 0.8s 0.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(9) {
  animation: fade-in 0.8s 0.9s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(10) {
  animation: fade-in 0.8s 1s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(11) {
  animation: fade-in 0.8s 1.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(12) {
  animation: fade-in 0.8s 1.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(13) {
  animation: fade-in 0.8s 1.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(14) {
  animation: fade-in 0.8s 1.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(15) {
  animation: fade-in 0.8s 1.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(16) {
  animation: fade-in 0.8s 1.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(17) {
  animation: fade-in 0.8s 1.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(18) {
  animation: fade-in 0.8s 1.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
} */

@keyframes fade-in {
  100% {
    opacity: 1;
    filter: blur(0);
  }
}

.poly1 {
  width: 100%;
  height: 100px;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(30%);
  clip-path: polygon(40% 0%, 0% 0%, 100% 0%, 100% 100%);
  background: rgb(255 255 255 / 10%);
}

.poly2 {
  width: 100%;
  height: 300px;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(30%);
  clip-path: polygon(0% 0%, 0% 0%, 100% 0%, 100% 100%);
  background: rgb(255 255 255 / 10%);
}

.poly3 {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(30%);
  clip-path: polygon(0% 100%, 0% 0%, 100% 20%, 100% 90%);
  background: rgb(255 255 255 / 5%);
}

.polyStaticInnerBottom1a {
  width: 100%;
  height: 80px;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateX(0%);
  clip-path: polygon(0% 100%, 80% 100%, -70% 0%, 0% 0%);
  z-index: 10;
}

.polyStaticInnerBottom1b {
  width: 100%;
  height: 220px;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateX(0%);
  clip-path: polygon(0% 100%, 80% 100%, -70% 0%, 0% 0%);
  z-index: 10;
}

.polyStaticInnerBottom2a {
  width: 100%;
  height: 100px;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateX(0%);
  clip-path: polygon(100% 0%, 100% 100%, 0% 100%, 60% 100%);
  z-index: 10;
}

.polyStaticInnerBottom2b {
  width: 100%;
  height: 80px;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateX(0%);
  clip-path: polygon(100% 0%, 100% 100%, 0% 100%, 60% 100%);
  z-index: 10;
}

.polyStaticInnerBottom2c {
  width: 100%;
  height: 50px;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateX(0%);
  clip-path: polygon(100% 0%, 100% 100%, 0% 100%, 50% 100%);
  z-index: 10;
}

.polyStatic1 {
  width: 100%;
  height: 100px;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(0%);
  clip-path: polygon(0% 0%, 0% 0%, 100% 0%, 100% 50%);
  background: rgb(255 255 255 / 10%);
}

.polyStatic2 {
  width: 100%;
  height: 100px;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(0%);
  clip-path: polygon(0% -30%, 0% 0%, 100% 0%, 100% 70%);
  background: rgb(255 255 255 / 10%);
}

.polyStatic3 {
  width: 100%;
  height: 100px;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateX(0%);
  clip-path: polygon(0% 100%, 80% 100%, -20% 0%, 0% 0%);
  background: rgb(255 255 255 / 10%);
}

.polyStatic4 {
  width: 100%;
  height: 100px;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateX(0%);
  clip-path: polygon(0% 100%, 100% 100%, -70% 0%, 0% 0%);
  background: rgb(255 255 255 / 10%);
}

.polyStaticTopRight {
  width: 100%;
  height: 100px;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(0%);
  clip-path: polygon(0% 0%, 0% 0%, 100% 0%, 100% 50%);
  z-index: 10;
}

.polyStaticBottLeft {
  width: 100%;
  height: 100px;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateX(0%);
  clip-path: polygon(0% 100%, 100% 100%, -70% 0%, 0% 0%);
  z-index: 10;
}

.polyStaticOuterBL {
  width: 100%;
  height: 100px;
  position: absolute;
  bottom: -100px;
  left: 0;
  transform: translateX(0%);
  clip-path: polygon(0% 0%, 0% 0%, 100% 0%, 100% 50%);
  /* background: rgb(203 151 130 / 30%); */
  z-index: 10;
}

.polyStaticOuterTL {
  width: 100%;
  height: 100px;
  position: absolute;
  top: -100px;
  left: 0;
  transform: translateX(0%);
  clip-path: polygon(0% 100%, 100% 100%, -70% 0%, 0% 0%);
  /* background: rgb(203 151 130 / 20%); */
  z-index: 10;
}

.polyStaticOuterTR {
  width: 100%;
  height: 100px;
  position: absolute;
  top: -100px;
  right: 0;
  transform: translateX(0%);
  clip-path: polygon(70% 100%, 0% 100%, 100% 100%, 100% 60%);
  /* background: rgb(203 151 130 / 20%); */
  z-index: 10;
}

/* Start: team image hover */
/* - */
/* - */
.image_holder {
  overflow: hidden;
}

.item_holder {
  position: relative;
}

.slow_zoom .image_holder img {
  -webkit-transform: scale(1.01);
  transform: scale(1.01);
  -webkit-transition: all 2s cubic-bezier(.21,1,.12,1) 0s;
  transition: all 2s cubic-bezier(.23,1,.12,1) 0s
}

.slow_zoom:hover .image_holder img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: all 5s cubic-bezier(.21,1,.12,1) 0s;
  transition: all 5s cubic-bezier(.21,1,.12,1) 0s
}

.text_holder {
  width: 100%;
  position: absolute;
  left: 0;
  height: 100%;
  margin: 0;
  top: 0;
  box-sizing: border-box;
  z-index: 15;
  -webkit-transition: opacity .6s .3s;
  transition: opacity .6s .3s;
  opacity: 0;
  background-color: transparent;
  background-color: rgb(158 214 207 / 28%);
}

.slow_zoom:hover .text_holder {
  opacity: 1;
}
/* - */
/* - */
/* End: team image hover */

button.buttonFillEffect {
  --c:  #fff; /* the color*/
  
  box-shadow: 0 0 0 .2em inset var(--c); 
  --_g: linear-gradient(var(--c) 0 0) no-repeat;
  background: 
    var(--_g) calc(var(--_p,0%) - 100%) 0%,
    var(--_g) calc(200% - var(--_p,0%)) 0%,
    var(--_g) calc(var(--_p,0%) - 100%) 100%,
    var(--_g) calc(200% - var(--_p,0%)) 100%;
  background-size: 50.5% calc(var(--_p,0%)/2 + .5%);
  outline-offset: .2em;
  transition: background-size .4s, background-position 0s .4s, color .4s;
}
button.buttonFillEffect:hover {
  --_p: 100%;
  transition: background-position .4s, background-size 0s, color .0s;
  color: #111;
}
button.buttonFillEffect:active {
  box-shadow: 0 0 9e9q inset #0009; 
  background-color: var(--c);
  color: #fff;
}
button.buttonFillEffect {
  cursor: pointer;
  border: none;
}

.mainMenuLink {
  font-size: 1.25rem;
}

@media (max-width: 1340px) {
  .mainMenuLink {
    font-size: 1rem;
    margin: 0;
  }
}

@media (max-width: 1140px) {
  .mainMenuLink {
    font-size: 0.8rem;
    margin: 0;
  }
}

@media (max-width: 760px) {
  .mainMenuLink {
    font-size: 2rem;
  }
}